<template>
  <list-template
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"
    hasAdd
		@onAdd="onAdd"
  >
  </list-template>
</template>
<script>
import {tableListMixin} from "@/utils/mixins";

export default {
  _config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  mixins: [tableListMixin],
  methods: {
    getData() {
      this.getDataApi('/api2/api/system-base/school-year/list')
    },
    onAdd() {
      this.$router.push('./add')
    },
		handleClick(row, handle) {
			if (handle.type === "detail") {
        return this.$router.push('./detail?id=' + row.id)
      }
      this.$router.push('./edit?id=' + row.id)
    }
  },
  data()  {
    return {
      tableConfig: [
        { prop: 'school_year_name', label: '学年名称' },
        { prop: 'last_start_date', label: '学年开始时间' },
        { prop: 'next_end_date', label: '学年结束时间' },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { prop: 'status_name', label: '状态' },
        { label: '操作', width: '100rem', handle: true, fixed: 'right', render(row) { return [row.status === 4 ? '查看' : '编辑'] } },
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
